<template>
  <div class="registrazione">
    <div class="row no-gutters">
      <div class="col-lg-10 offset-lg-1">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("changePassword.title") }}
            </h1>
            <p>
              {{ $t("changePassword.general") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-10 offset-lg-1">
        <div class="col-sm-6 offset-sm-3  ">
        <b-alert class="mt-3" variant="danger" id="errore_danger" v-model="error" >{{ $t("changePassword.errors."+this.errorCode) }}</b-alert>
        <div class="m-card m-card--editdata mt-3">
          <div class="m-card__form">
            <b-form-group
                id="password"
                :label="this.$t('changePassword.password_label')"
                label-for="email-input"
                class="m-input m-input__icon m-input__password"
            >
              <div class="icon-codice-referto mr-3"></div>
              <div
                class="icon-eye mr-3 m-input__password"
                @mousedown="changeTypeInput('password-input')"
              ></div>
              <b-form-input
                  id="password-input"
                  v-model="password"
                  type="password"
                  :state="isPassword(password)"
                  :placeholder="this.$t('changePassword.password_placeholder')"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="password2"
                :label="this.$t('changePassword.repeat_password_label')"
                label-for="email-input"
                class="m-input m-input__icon m-input__password"
            >
              <div class="icon-codice-referto mr-3"></div>
              <div
                class="icon-eye mr-3 m-input__password"
                @mousedown="changeTypeInput('password-input-conf')"
              ></div>              
              <b-form-input
                  id="password-input-conf"
                  v-model="passwordConf"
                  type="password"
                  :state="(password==passwordConf ? null : false)"
                  :placeholder="this.$t('changePassword.repeat_password_placeholder')"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="m-card__action">
            <b-button :disabled="password!=passwordConf || password === '' || isPassword(password) === false" @click="doChange()" class=" mb-1" variant="primary" size="md">{{
                $t("generic.edit")
              }}</b-button>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>

</template>

<script>
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
import store from "@/store";

export default {
  name: "changePassword",
  data:function (){
    return {
      password:"",
      passwordConf:"",
      error:false,
      errorCode:"",
    };
  },
  computed:{
    userData:function(){
      console.log(sessionStorage.getItem("userdata"));
      return JSON.parse(sessionStorage.getItem("userdata"));
    }
  },
  methods: {
    async reloadUser()
    {
      await store.dispatch("dateUser/loadUser").then(() => {
        console.log("Userdata OK:"+JSON.stringify(store.getters['dateUser/UserData']));
        router.push("/");
      });
    },
    doChange: function ()
    {
      if (this.password!=this.passwordConf)
      {
        alert("Diverse");
        return;
      }
      const data={
        newPassword: this.password
      }
      apiAxios.post("/user/changePassword",data)
          .then(response => {
            console.log(response);
            if(response.data.ChangePasswordResult === true)
              this.reloadUser();
            else {
              this.error = true;
              this.errorCode = response.data.ChangePasswordResult;
            }
          })
          .catch(error=> {
            console.log(error);
          });
    },
    async getUserData() {
      try {
        await this.$store.dispatch("dateUser/getUser").then(() => {
          this.payload = this.$store.getters["dateUser/UserData"];
          console.log(this.payload);
          return this.payload;
        });
      } catch (e) {
        console.log({ message: "Some error getUser" });
      }
    },
    changeTypeInput(id) {
      if (document.getElementById(id).type !== "text") {
        document.getElementById(id).type = "text";
      } else {
        document.getElementById(id).type = "password";
      }
    },    
    isPassword(password) {
      const regExp = /^(?=.*[A-Za-z]+)(?=.*\d)[A-Za-z\d\\!"£$%&/()=?^*#.:,;-_<>]{8,}$/;
      if (password != null && password !== '') {
        if (regExp.test(password) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }    
    }
  }

}
</script>

<style scoped>

</style>
